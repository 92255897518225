export const GET_BOOK_REQUEST = "GET_BOOK_REQUEST";
export const GET_BOOK_SUCCESS = "GET_BOOK_SUCCESS";
export const GET_BOOK_FAILED = "GET_BOOK_FAILED";

export const GET_ALL_BOOK_REQUEST = "GET_ALL_BOOK_REQUEST";
export const GET_ALL_BOOK_SUCCESS = "GET_ALL_BOOK_SUCCESS";
export const GET_ALL_BOOK_FAILED = "GET_ALL_BOOK_FAILED";

export const GET_AVG_BOOK_RATING_REQUEST = "GET_AVG_BOOK_RATING_REQUEST";
export const GET_AVG_BOOK_RATING_SUCCESS = "GET_AVG_BOOK_RATING_SUCCESS";
export const GET_AVG_BOOK_RATING_FAILED = "GET_AVG_BOOK_RATING_FAILED";

export const GET_BOOK_RATING_BY_USER_REQUEST = "GET_BOOK_RATING_BY_USER_REQUEST";
export const GET_BOOK_RATING_BY_USER_SUCCESS = "GET_BOOK_RATING_BY_USER_SUCCESS";
export const GET_BOOK_RATING_BY_USER_FAILED = "GET_BOOK_RATING_BY_USER_FAILED";

export const GET_LANGUAGES_BY_BOOK_REQUEST = "GET_LANGUAGES_BY_BOOK_REQUEST";
export const GET_LANGUAGES_BY_BOOK_SUCCESS = "GET_LANGUAGES_BY_BOOK_SUCCESS";
export const GET_LANGUAGES_BY_BOOK_FAILED = "GET_LANGUAGES_BY_BOOK_FAILED";

export const GET_ALL_LANGUAGES_REQUEST = "GET_ALL_LANGUAGES_REQUEST";
export const GET_ALL_LANGUAGES_SUCCESS = "GET_ALL_LANGUAGES_SUCCESS";
export const GET_ALL_LANGUAGES_FAILED = "GET_ALL_LANGUAGES_FAILED";

export const BOOK_UPLOAD_REQUEST = "BOOK_UPLOAD_REQUEST";
export const BOOK_UPLOAD_SUCCEED = "BOOK_UPLOAD_SUCCEED";
export const BOOK_UPLOAD_FAILED = "BOOK_UPLOAD_FAILED";

export const BOOK_EDIT_REQUEST = "BOOK_EDIT_REQUEST";
export const BOOK_EDIT_SUCCEED = "BOOK_EDIT_SUCCEED";
export const BOOK_EDIT_FAILED = "BOOK_EDIT_FAILED";

export const BOOK_DELETE_REQUEST = "BOOK_DELETE_REQUEST";
export const BOOK_DELETE_SUCCEED = "BOOK_DELETE_SUCCEED";
export const BOOK_DELETE_FAILED = "BOOK_DELETE_FAILED";

export const FOLLOW_BOOK_REQUEST = "FOLLOW_BOOK_REQUEST";
export const FOLLOW_BOOK_SUCCESS = "FOLLOW_BOOK_SUCCESS";
export const FOLLOW_BOOK_FAILED = "FOLLOW_BOOK_FAILED";

export const RATING_BOOK_REQUEST = "RATING_BOOK_REQUEST";
export const RATING_BOOK_SUCCESS = "RATING_BOOK_SUCCESS";
export const RATING_BOOK_FAILED = "RATING_BOOK_FAILED";

export const SEARCH_BOOK_REQUEST = "SEARCH_BOOK_REQUEST";
export const SEARCH_BOOK_SUCCESS = "SEARCH_BOOK_SUCCESS";
export const SEARCH_BOOK_FAILED = "SEARCH_BOOK_FAILED";

export const ADD_NEW_LANGUAGE_REQUEST = "ADD_NEW_LANGUAGE_REQUEST";
export const ADD_NEW_LANGUAGE_SUCCESS = "ADD_NEW_LANGUAGE_SUCCESS";
export const ADD_NEW_LANGUAGE_FAILED = "ADD_NEW_LANGUAGE_FAILED";

export const EDIT_LANGUAGE_REQUEST = "EDIT_LANGUAGE_REQUEST";
export const EDIT_LANGUAGE_SUCCESS = "EDIT_LANGUAGE_SUCCESS";
export const EDIT_LANGUAGE_FAILED = "EDIT_LANGUAGE_FAILED";

export const DELETE_LANGUAGE_REQUEST = "DELETE_LANGUAGE_REQUEST";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAILED = "DELETE_LANGUAGE_FAILED";
