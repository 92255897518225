export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";

export const SUSPEND_USER_REQUEST = "SUSPEND_USER_REQUEST";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const SUSPEND_USER_FAILED = "SUSPEND_USER_FAILED";

export const UNSUSPEND_USER_REQUEST = "UNSUSPEND_USER_REQUEST";
export const UNSUSPEND_USER_SUCCESS = "UNSUSPEND_USER_SUCCESS";
export const UNSUSPEND_USER_FAILED = "UNSUSPEND_USER_FAILED";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
