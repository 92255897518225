export const GET_ALL_BOOK_COMMENT_REQUEST = "GET_ALL_BOOK_COMMENT_REQUEST";
export const GET_ALL_BOOK_COMMENT_SUCCESS = "GET_ALL_BOOK_COMMENT_SUCCESS";
export const GET_ALL_BOOK_COMMENT_FAILED = "GET_ALL_BOOK_COMMENT_FAILED";

export const CREATE_COMMENT_REQUEST = "CREATE_COMMENT_REQUEST";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILED = "CREATE_COMMENT_FAILED";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED";

export const GET_ALL_SENSITIVE_WORDS_REQUEST = "GET_ALL_SENSITIVE_WORDS_REQUEST";
export const GET_ALL_SENSITIVE_WORDS_SUCCESS = "GET_ALL_SENSITIVE_WORDS_SUCCESS";
export const GET_ALL_SENSITIVE_WORDS_FAILED = "GET_ALL_SENSITIVE_WORDS_FAILED";

export const ADD_SENSITIVE_WORD_REQUEST = "ADD_SENSITIVE_WORD_REQUEST";
export const ADD_SENSITIVE_WORD_SUCCESS = "ADD_SENSITIVE_WORD_SUCCESS";
export const ADD_SENSITIVE_WORD_FAILED = "ADD_SENSITIVE_WORD_FAILED";

export const DELETE_SENSITIVE_WORD_REQUEST = "DELETE_SENSITIVE_WORD_REQUEST";
export const DELETE_SENSITIVE_WORD_SUCCESS = "DELETE_SENSITIVE_WORD_SUCCESS";
export const DELETE_SENSITIVE_WORD_FAILED = "DELETE_SENSITIVE_WORD_FAILED";
